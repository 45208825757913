<template>
  <div>
    <div class="my-1 d-flex justify-content-between">
      <b-button
        @click="$router.go(-1)"
        variant="outline-warning"
        class="btn-icon"
        size="sm"
      >
        <span class="mb-6">RETOUR</span>
      </b-button>
      <b-button
        @click="printInventaire()"
        variant="primary"
        class="btn-icon"
        size="sm"
      >
        <span class="mb-6">IMPRIMER CET INVENTAIRE</span>
      </b-button>
    </div>
    <b-card>
      <div
        class="
          d-flex
          flex-sm-row flex-column flex-wrap
          justify-content-between
          mb-1 mb-sm-0
        "
      >
        <div class="text-center text-uppercase">
          <h6>Date inventaire</h6>
          <b-badge class="text-uppercase ml-1" variant="success">
            <small class="timeline-item-time text-nowrap">
              {{ inventaireData.created_at }}
            </small>
          </b-badge>
        </div>
        <div class="text-center text-uppercase">
          <h6>Période inventaire</h6>
          <b-badge class="text-uppercase ml-1" variant="success">
            <small class="timeline-item-time text-nowrap">
              Du {{ inventaireData.from }} au {{ inventaireData.to }}
            </small>
          </b-badge>
        </div>
        <div class="text-center text-uppercase">
          <h6>NOM de l'association</h6>
          <b-badge class="text-uppercase ml-1" variant="success">
            <small class="timeline-item-time text-nowrap">{{
              inventaireData.association ? inventaireData.association.name : ""
            }}</small>
          </b-badge>
        </div>
        <div class="text-center text-uppercase">
          <h6>Code de l'association</h6>
          <b-badge class="text-uppercase ml-1" variant="success">
            <small class="timeline-item-time text-nowrap">{{
              inventaireData.association ? inventaireData.association.code : ""
            }}</small>
          </b-badge>
        </div>
      </div>
    </b-card>
    <div class="row">
      <b-card class="col mx-1 text-center bg-primary text-white">
        <h6 class="border-bottom mb-1 pb-1 text-white">Capital précédent</h6>
        <span>{{ $thousandSeparator(inventaireData.capital_precedent) }}</span>
      </b-card>
      <b-card class="col mx-1 text-center bg-primary text-white">
        <h6 class="border-bottom mb-1 pb-1 text-white">Bénéfice</h6>
        <span>{{ $thousandSeparator(inventaireData.benefice_net) }}</span>
      </b-card>
      <b-card class="col mx-1 text-center bg-primary text-white">
        <h6 class="border-bottom mb-1 pb-1 text-white">Total des retraits</h6>
        <span>{{ $thousandSeparator(inventaireData.total_retraits) }}</span>
      </b-card>
      <b-card class="col mx-1 text-center bg-primary text-white">
        <h6 class="border-bottom mb-1 pb-1 text-white">Nouveau capital</h6>
        <span>{{ $thousandSeparator(inventaireData.nouveau_capital) }}</span>
      </b-card>
    </div>
    <div class="row">
      <b-card class="col-3 mr-2 ml-1 px-0 text-center">
        <h6
          class="border-bottom mb-1 bg-dark rounded text-white"
          style="padding-top: 5px; padding-bottom: 5px"
        >
          VÉHICULES
        </h6>
        <div class="row px-1">
          <div class="col p-0" style="font-size: 0.8em">
            <b-badge variant="success">VENDUS</b-badge>
            <div>{{ inventaireData.vehicules_vendus }}</div>
          </div>

          <div class="col p-0" style="font-size: 0.8em">
            <b-badge variant="dark">STOCK</b-badge>
            <div>{{ inventaireData.vehicules_en_stock }}</div>
          </div>

          <div class="col p-0" style="font-size: 0.8em">
            <b-badge variant="primary">COURS</b-badge>
            <div>{{ inventaireData.vehicules_en_cours_de_vente }}</div>
          </div>
        </div>
      </b-card>
      <b-card class="col mr-2 px-0 text-center ml-2">
        <h6
          class="border-bottom mb-1 bg-dark rounded text-white"
          style="padding-top: 5px; padding-bottom: 5px"
        >
          DETTES
        </h6>
        <div class="row px-1">
          <div class="col p-0" style="font-size: 0.8em">
            <b-badge variant="dark">Dédommagements</b-badge>
            <div>{{ $thousandSeparator(inventaireData.refunds) }}</div>
          </div>

          <div class="col p-0" style="font-size: 0.8em">
            <b-badge variant="dark">Carte Importateur</b-badge>
            <div>{{ $thousandSeparator(inventaireData.total_cartes_imp) }}</div>
          </div>

          <div class="col p-0" style="font-size: 0.8em">
            <b-badge variant="dark">Chargeurs</b-badge>
            <div>{{ $thousandSeparator(inventaireData.total_chargeurs) }}</div>
          </div>
          <div class="col p-0" style="font-size: 0.8em">
            <b-badge variant="dark">Frais</b-badge>
            <div>{{ $thousandSeparator(inventaireData.total_frais) }}</div>
          </div>
          <div class="col p-0" style="font-size: 0.8em">
            <b-badge variant="dark">Factures</b-badge>
            <div>
              {{ $thousandSeparator(inventaireData.total_factures) }}
            </div>
          </div>
        </div>
      </b-card>
    </div>

    <div class="row">
      <b-card class="col mx-1 text-center bg-primary text-white">
        <div class="border-bottom mb-1 pb-1">
          <h6 class="text-white">Montant en caisse</h6>
          <!-- <b-img rounded class="icone" fluid :src="iconeMCaisse" /> -->
        </div>
        <span>{{ $thousandSeparator(inventaireData.cash) }}</span>
      </b-card>

      <b-card class="col mr-1 text-center bg-primary text-white">
        <div class="border-bottom mb-1 pb-1">
          <h6 class="text-white">Valeur du stock</h6>
          <!-- <b-img rounded class="icone" fluid :src="iconeValeurStock" /> -->
        </div>
        <span>{{ $thousandSeparator(inventaireData.valeur_stock) }}</span>
      </b-card>

      <b-card class="col mr-1 text-center bg-primary text-white">
        <div class="border-bottom mb-1 pb-1">
          <h6 class="text-white">Montant dans les comptes</h6>
          <!-- <b-img rounded class="icone" fluid :src="iconeMCompte" /> -->
        </div>
        <span>
          {{ $thousandSeparator(inventaireData.montant_comptes) }}
        </span>
      </b-card>

      <b-card class="col mr-1 px-0 text-center bg-primary text-white">
        <div class="border-bottom mb-1 pb-1">
          <h6 class="text-white">Marge vente</h6>
          <!-- <b-img rounded class="icone" fluid :src="iconeMarge" /> -->
        </div>
        <span
          >{{
            inventaireData.benefices_vehicule_en_stock
              ? $thousandSeparator(inventaireData.benefices_vehicule_en_stock)
              : 0
          }}
        </span>
      </b-card>

      <b-card class="col mr-1 px-0 text-center bg-primary text-white">
        <div class="border-bottom mb-1 pb-1">
          <h6 class="text-white">Montant à recouvrer</h6>
          <!-- <b-img rounded class="icone" fluid :src="iconeRecouvrement" /> -->
        </div>
        <span>{{
          inventaireData.reste_sur_avance
            ? $thousandSeparator(inventaireData.reste_sur_avance)
            : 0
        }}</span>
      </b-card>

      <b-card class="col mr-1 px-0 text-center bg-primary text-white">
        <div class="border-bottom mb-1 pb-1">
          <h6 class="text-white">Dépenses</h6>
          <!-- <b-img rounded class="icone" fluid :src="iconeDepenses" /> -->
        </div>
        <span>{{
          inventaireData.depenses
            ? $thousandSeparator(inventaireData.depenses)
            : 0
        }}</span>
      </b-card>
    </div>

    <!-- Associes -->
    <b-card class="mt-1">
      <h3>Repartition des bénéfices</h3>
      <hr />
      <div>
        <b-table-simple hover medium caption-top responsive>
          <b-thead head-variant="dark">
            <b-tr>
              <b-th>Nom & Prénom(s)</b-th>
              <b-th>Capital précédent</b-th>
              <b-th>Retrait</b-th>
              <b-th>Bénéfice</b-th>
              <b-th>Nouveau capital</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="(item, index) in associes" :key="index">
              <b-th>{{ item.name }} </b-th>
              <b-td>{{ $thousandSeparator(item.capital) }}</b-td>
              <b-td>{{ $thousandSeparator(item.retrait) }}</b-td>
              <b-td>{{ $thousandSeparator(item.benefice) }}</b-td>
              <b-td>{{ $thousandSeparator(item.new_capital) }}</b-td>
            </b-tr>
          </b-tbody>
          <b-tfoot>
            <b-tr>
              <b-td variant="secondary"> Total: </b-td>
              <b-td variant="secondary"></b-td>
              <b-td variant="secondary">
                <b>
                  {{
                    $thousandSeparator(
                      associes.reduce(function (sum, current) {
                        return sum + current.retrait * 1;
                      }, 0)
                    )
                  }}</b
                >
              </b-td>
              <b-td variant="secondary"></b-td>
              <b-td variant="secondary">
                <b>
                  {{
                    $thousandSeparator(
                      associes.reduce(function (sum, current) {
                        return sum + current.new_capital * 1;
                      }, 0)
                    )
                  }}</b
                >
              </b-td>
            </b-tr>
          </b-tfoot>
        </b-table-simple>
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BCard,
  BTable,
  BCardText,
  BIconFileEarmarkArrowDownFill,
  BForm,
  BRow,
  BCol,
  BImg,
  BMedia,
  BMediaAside,
  BButton,
  BMediaBody,
  BIconArrowClockwise,
  BAvatar,
  BBadge,
  BTableSimple,
  BTbody,
  BThead,
  BTh,
  BTd,
  BTr,
  BTfoot,
} from "bootstrap-vue";

export default {
  components: {
    BTableSimple,
    BButton,
    BTbody,
    BThead,
    BTh,
    BTd,
    BTr,
    BTfoot,
    BBadge,
    BCard,
    BTable,
    BAvatar,
    BMedia,
    BMediaAside,
    BCardText,
    BMediaBody,
    BIconFileEarmarkArrowDownFill,
    BForm,
    BRow,
    BCol,
    BIconArrowClockwise,
    BImg,
  },
  data() {
    return {
      iconeDepenses: require("@/assets/images/icons/depenses.png"),
      iconeMarge: require("@/assets/images/icons/marge-vente.png"),
      iconeMCaisse: require("@/assets/images/icons/montant-caisse.png"),
      iconeMCompte: require("@/assets/images/icons/montant-comptes.png"),
      iconeRecouvrement: require("@/assets/images/icons/recouvrement.png"),
      iconeValeurStock: require("@/assets/images/icons/valeur-stock.png"),
      inventaireData: {},
      associes: [],
      association: {},
    };
  },
  computed: {
    dateDernierInventaire() {
      if (this.association.dernier_inventaire) {
        return this.inventaireData.dernier_inventaire;
      } else {
        return this.association.created_at;
      }
    },
    maxDate() {
      let date = new Date();
      let annee = date.getFullYear();
      let mois = ("0" + (date.getMonth() + 1)).slice(-2);
      let jour = ("0" + date.getDate()).slice(-2);
      let heure = ("0" + date.getHours()).slice(-2);
      let minute = ("0" + date.getMinutes()).slice(-2);
      date = `${jour}-${mois}-${annee} à ${heure}:${minute}`;
      return date;
    },

    ...mapState({
      user: (state) => state.user.auth,
    }),
    totalRows() {
      return this.associes.length;
    },
    imgUrl() {
      this.sideImg = require("@/assets/images/association.png");
      return this.sideImg;
    },
  },
  created() {
    this.showAssociation();
  },
  mounted() {},
  methods: {
    showToast(titre, type) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: titre,
          icon: type === "success" ? "CheckSquareIcon" : "AlertCircleIcon",
          variant: type,
        },
      });
    },

    printInventaire() {
      // this.$router.push({
      //   name: "inventaire-show",
      //   params: { id: item.id },
      // });
      let routeData = this.$router.resolve({
        name: "inventaire-print",
        params: { id: this.inventaireData.id },
      });
      window.open(routeData.href, "_blank");
    },

    showAssociation() {
      let id = this.$router.currentRoute.params.id;
      this.$http.get("/inventaires/" + id).then((response) => {
        this.inventaireData = response.data.data;
        this.associes = JSON.parse(this.inventaireData.repartition);
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "../../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-vue-dropdowns/styles/material.css";

.e-input-group {
  border-color: rgb(0 0 0 / 15%) !important;
  border-width: 1px !important;
  border-radius: 5px !important;
  color: rgb(127 125 139) !important;
  line-height: 2.1 !important;
  padding-left: 15px !important;
}

.e-input-group::before,
.e-input-group::after {
  background: #e3165b00 !important;
}

.detail_organisation {
  height: 200px;
}

.img-fluid {
  max-width: 40% !important;
}

.icone {
  max-width: 12% !important;
}
</style>